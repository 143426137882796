/* import __COLOCATED_TEMPLATE__ from './voice-playground.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type FinVoiceService from 'embercom/services/fin-voice-service';
import { tracked } from '@glimmer/tracking';
import type { FinVoiceVadSettings } from 'embercom/objects/ai-agent/fin-voice/fin-voice-events';
import type { FinVoiceVoice } from 'embercom/objects/ai-agent/fin-voice/fin-voice-voice-options';
import type { AudioDeviceSettings } from 'embercom/services/fin-voice-service';

export default class AiAgentVoicePlayground extends Component {
  @service declare finVoiceService: FinVoiceService;

  @tracked isLogsVisible = true;
  @tracked verboseLogsEnabled = false;
  @tracked isSettingsOpen = false;

  get isMuted() {
    return this.finVoiceService.isMuted;
  }

  get connectionState() {
    return this.finVoiceService.websocketState;
  }

  get sessions() {
    return this.finVoiceService.sessions;
  }

  get vadSettings() {
    return this.finVoiceService.vadSettings;
  }

  get selectedVoice() {
    return this.finVoiceService.selectedVoice;
  }

  get audioDeviceSettings() {
    return this.finVoiceService.audioDeviceSettings;
  }

  constructor(owner: unknown, args: any) {
    super(owner, args);
  }

  willDestroy() {
    super.willDestroy();
  }

  @action
  toggleLogsVisibility() {
    this.isLogsVisible = !this.isLogsVisible;
  }

  @action
  toggleVerboseLogsEnabled() {
    this.verboseLogsEnabled = !this.verboseLogsEnabled;
  }

  @action
  connect() {
    return this.finVoiceService.connectStream();
  }

  @action
  disconnect() {
    return this.finVoiceService.disconnectStream();
  }

  @action
  mute() {
    return this.finVoiceService.muteAudio();
  }

  @action
  unmute() {
    return this.finVoiceService.unmuteAudio();
  }

  @action
  openSettings() {
    this.isSettingsOpen = true;
  }

  @action
  closeSettings() {
    this.isSettingsOpen = false;
  }

  @action
  saveSettings(
    vadSettings: FinVoiceVadSettings,
    voice: FinVoiceVoice,
    audioDeviceSettings: AudioDeviceSettings,
  ) {
    this.finVoiceService.vadSettings = vadSettings;
    this.finVoiceService.selectedVoice = voice;
    this.finVoiceService.audioDeviceSettings = audioDeviceSettings;
    this.isSettingsOpen = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::VoicePlayground': typeof AiAgentVoicePlayground;
  }
}
