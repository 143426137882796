/* import __COLOCATED_TEMPLATE__ from './view-suggestions.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import {
  type CustomerAnswerRating,
  CustomerRatingReason,
  type PlaygroundQuestionSource,
  type PlaygroundQuestion,
} from 'embercom/lib/fin-playground';
import { action } from '@ember/object';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import { objectNames } from 'embercom/models/data/matching-system/matching-constants';
import type RouterService from '@ember/routing/router-service';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import type Store from '@ember-data/store';
import type PersonalitySettings from 'embercom/models/ai-agent/tone-of-voice-settings';
import { tracked } from '@glimmer/tracking';
import { EntityType } from 'embercom/models/data/entity-types';
import { task } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { guidelineWithoutTemplateVars } from 'embercom/helpers/ai-agent/playground-helper';

interface Locale {
  name: string;
  localeId: string;
}

interface ViewSuggestionsArgs {
  isOpen: boolean;
  onClose: () => void;
  question: PlaygroundQuestion;
  onUpdateQuestionRating: (
    questionId: number,
    args: { rating?: CustomerAnswerRating; reason?: CustomerRatingReason },
  ) => void;
  toggleSuggestionsSideSheet: () => void;
}

type ViewSuggestionsSignature = {
  Args: ViewSuggestionsArgs;
  Element: HTMLDivElement;
};

export default class ViewSuggestions extends Component<ViewSuggestionsSignature> {
  CustomerRatingReason = CustomerRatingReason;

  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare router: RouterService;
  @service declare appService: $TSFixMe;
  @service declare knowledgeHubService: KnowledgeHubService;
  @tracked personalitySettings: PersonalitySettings | null = null;
  @tracked languages: $TSFixMe;
  @tracked multilingualSettings: $TSFixMe;
  constructor(owner: unknown, args: $TSFixMe) {
    super(owner, args);
    taskFor(this.getLanguages).perform();
    taskFor(this.getMultilingualSettings).perform();
  }

  get isStandaloneApp() {
    return this.appService.app.canUseStandalone;
  }

  @action
  selectRatingReason(reason: CustomerRatingReason) {
    if (!this.args.question?.id) {
      return;
    }

    this.args.onUpdateQuestionRating(this.args.question.id, { reason });
  }

  @action
  createSnippet() {
    this.knowledgeHubService.createContent(EntityType.ContentSnippet, {}, true, false);
  }

  @action
  editContent(source: PlaygroundQuestionSource) {
    safeWindowOpen(
      this.router.urlFor(
        'apps.app.knowledge-hub.view',
        objectNames[source.entityType],
        source.entityId,
      ),
      '_blank',
    );
  }

  @action
  createGuidance() {
    if (this.isStandaloneApp) {
      safeWindowOpen(this.router.urlFor('apps.app.standalone.guidance'), '_blank');
    } else {
      safeWindowOpen(this.router.urlFor('apps.app.automation.fin-ai-agent.guidance'), '_blank');
    }
  }

  @action
  editPersonality() {
    if (this.isStandaloneApp) {
      safeWindowOpen(this.router.urlFor('apps.app.standalone.personality'), '_blank');
    } else {
      safeWindowOpen(
        this.router.urlFor('apps.app.automation.fin-ai-agent.setup', {
          queryParams: {
            activeTab: 'personality',
          },
        }),
        '_blank',
      );
    }
  }

  @action
  addMoreLanguages() {
    safeWindowOpen(this.router.urlFor('apps.app.settings.workspace.general'), '_blank');
  }

  @action
  goToSettings() {
    safeWindowOpen(
      this.router.urlFor('apps.app.settings.ai-automation.fin-ai-agent', {
        queryParams: {
          section: 'multilingual-settings',
        },
      }),
      '_blank',
    );
  }

  get hasGuidelines() {
    return this.args.question.appliedGuidelines.length > 0;
  }

  get guidelineContent() {
    return this.args.question.appliedGuidelines
      .map((guideline) => guideline.text.split('\n').map(guidelineWithoutTemplateVars))
      .flat();
  }

  get toneOfVoice() {
    return this.intl.t(
      `ai-agent.settings.tone-of-voice.tones.${this.args.question.aiToneOfVoice}.name`,
    );
  }

  get answerLength() {
    return this.intl.t(
      `ai-agent.settings.answer-length.values.${this.args.question.aiAnswerLength}.name`,
    );
  }

  get isGetLanguagesLoading() {
    return taskFor(this.getLanguages).isRunning;
  }

  get defaultLocaleName() {
    return this.languages.supportedLocales.find(
      (locale: Locale) => locale.localeId === this.languages.defaultLocale,
    )?.name;
  }

  get supportedLanguages() {
    let supportedLanguages = this.languages.supportedLocales
      .filter((locale: any) => locale.isPermitted)
      .map((locale: any) => locale.name);

    supportedLanguages.unshift(
      `${this.defaultLocaleName} (${this.intl.t('ai-agent.settings.multilingual.real-time-translation.default')})`,
    );
    return supportedLanguages.join(', ');
  }

  get isGetMultilingualSettingsLoading() {
    return taskFor(this.getMultilingualSettings).isRunning;
  }

  @task({ restartable: true })
  *getLanguages(): TaskGenerator<void> {
    let settings = yield this.store.findRecord('messenger-settings/all', this.appService.app.id);
    this.languages = settings.languages;
  }

  @task({ restartable: true })
  *getMultilingualSettings(): TaskGenerator<$TSFixMe> {
    let settings = yield this.store.findRecord(
      'ai-agent/multilingual-settings',
      this.appService.app.id,
    );
    this.multilingualSettings = settings;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Playground::ViewSuggestions': typeof ViewSuggestions;
  }
}
