/* import __COLOCATED_TEMPLATE__ from './suggestions.hbs'; */
/* RESPONSIBLE TEAM: team-conversational-knowledge */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import { tracked } from '@glimmer/tracking';
import { getOwner } from '@ember/application';
import { action } from '@ember/object';
import type ContentWrapper from 'embercom/models/content-service/content-wrapper';
import AiContentLibraryApi from 'embercom/lib/ai-content-library/list-api';
import { EntityType } from 'embercom/models/data/entity-types';
import { ContentWrapperType } from 'embercom/models/content-service/content-wrapper';
import { Status } from 'embercom/models/data/content-service/content-review-statuses';
import { PAGE_SIZE } from 'embercom/models/data/outbound/constants';
import { cached } from 'tracked-toolbox';
import { objectNames } from 'embercom/models/data/matching-system/matching-constants';
import { restartableTask } from 'ember-concurrency-decorators';
import { put } from 'embercom/lib/ajax';
import { taskFor } from 'ember-concurrency-ts';
import { EditorMode } from 'embercom/components/knowledge-hub/content-editor/drawer-editor';

type TopicItem = { topic: string; count: number; items: Array<ContentWrapper> };
type ReviewAction = 'approve' | 'reject';

export default class AiInsightsSuggestions extends Component {
  @service declare intl: IntlService;
  @service declare appService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;

  @tracked listApi?: AiContentLibraryApi;
  @tracked selectedTopicIndex?: number;
  @tracked editingContentId?: number;
  @tracked editingContentType?: string;
  @tracked editingContentMode?: EditorMode;

  constructor(owner: any, args: any) {
    super(owner, args);
    taskFor(this.loadSuggestions).perform();
  }

  @action
  selectTopic(idx: number) {
    this.selectedTopicIndex = idx;
  }

  @action
  editVersion(item: ContentWrapper) {
    this.editingContentId = item.entityId;
    this.editingContentType = objectNames[item.entityType];
    this.editingContentMode = EditorMode.REVIEW;
    this.selectedTopicIndex = undefined;
  }

  @action
  stopEditing() {
    this.editingContentId = undefined;
    this.editingContentType = undefined;
    this.editingContentMode = undefined;
  }

  @action
  approveVersion(item: ContentWrapper) {
    taskFor(this.bulkReview).perform('approve', [
      { entity_id: item.entityId!, entity_type: item.entityType },
    ]);

    this.notificationsService.notifyConfirmation(
      this.intl.t('automation.ai-insights.suggestions.approve-success'),
    );
  }

  @action
  rejectVersion(item: ContentWrapper) {
    taskFor(this.bulkReview).perform('reject', [
      { entity_id: item.entityId!, entity_type: item.entityType },
    ]);

    this.notificationsService.notifyConfirmation(
      this.intl.t('automation.ai-insights.suggestions.reject-success'),
    );
  }

  get isLoadingSuggestions() {
    return taskFor(this.loadSuggestions).isRunning;
  }

  get selectedTopic() {
    if (this.selectedTopicIndex === undefined) {
      return undefined;
    }

    return this.topics[this.selectedTopicIndex];
  }

  @cached
  get topics() {
    let topics: Array<TopicItem> = [];

    this.listApi?.contentWrappers.forEach((wrapper) => {
      let topic = wrapper.contentReviewSuggestionMetadata.topic;
      let existing = topics.find((t) => t.topic === topic);

      if (existing) {
        existing.count++;
        existing.items = [...existing.items, wrapper];
      } else {
        topics.push({ topic, count: 1, items: [wrapper] });
      }
    });

    return topics;
  }

  @restartableTask private *loadSuggestions() {
    let api = new AiContentLibraryApi(getOwner(this), {
      object_types: [EntityType.ArticleContent, EntityType.ContentSnippet],
      content_wrapper_types: [ContentWrapperType.AI_CONTENT],
      app_id: this.appService.app.id as string,
      per_page: PAGE_SIZE,
      content_review_statuses: [Status.Pending],
      title: '',
    });

    yield api.loadPage();
    this.listApi = api;
  }

  @restartableTask private *bulkReview(
    action: ReviewAction,
    contents: { entity_id: number; entity_type: EntityType }[],
  ) {
    let actionUrl = {
      approve: 'bulk_approve_suggestion',
      reject: 'bulk_reject_suggestion',
    };

    yield put(`/ember/content_service/contents/${actionUrl[action]}`, {
      app_id: this.appService.app.id,
      admin_id: this.appService.app.currentAdmin.id,
      contents_to_review: contents,
      is_bulk_selection: false,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiInsights::Suggestions': typeof AiInsightsSuggestions;
  }
}
