/* import __COLOCATED_TEMPLATE__ from './add-questions.hbs'; */
/* RESPONSIBLE TEAM: team-ml */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import { TrackedArray } from 'tracked-built-ins';

interface AddQuestionsArgs {
  isOpen: boolean;
  onClose: () => void;
  onSave: (questions: string[]) => Promise<void>;
  questionQuota: number;
  questionLimit: number;
}

class QuestionInput {
  @tracked text = '';

  constructor(text = '') {
    this.text = text;
  }
}

export default class AddQuestions extends Component<AddQuestionsArgs> {
  @service declare intl: IntlService;

  @tracked questions: TrackedArray<QuestionInput> = new TrackedArray([new QuestionInput()]);

  get questionsHaveContent() {
    return this.questions.some((q) => q.text.trim());
  }

  @action
  addQuestion() {
    this.questions.push(new QuestionInput());
  }

  @action
  removeQuestion(index: number) {
    this.questions.splice(index, 1);
  }

  @action
  async save() {
    await this.args.onSave(this.questions.map((q) => q.text));
    this.onClose();
  }

  @action
  onClose() {
    this.questions = new TrackedArray([new QuestionInput()]);
    this.args.onClose();
  }

  @action
  handleNewLine(index: number, event: KeyboardEvent) {
    event.preventDefault();
    this.questions = new TrackedArray([
      ...this.questions.slice(0, index + 1),
      new QuestionInput(),
      ...this.questions.slice(index + 1),
    ]);
  }

  @action
  handlePaste(index: number, event: ClipboardEvent) {
    event.preventDefault();

    let pasteData = event.clipboardData?.getData('text');

    if (pasteData) {
      let newQuestions = pasteData
        .split(/\n/)
        .map((text) => text.trim())
        .filter((text) => text);

      if (newQuestions.length > 0) {
        this.questions = new TrackedArray(
          [
            ...this.questions.slice(0, index),
            ...newQuestions.map((text) => new QuestionInput(text)),
            ...this.questions.slice(index + 1),
          ].slice(0, this.args.questionQuota),
        );
      }
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Playground::AddQuestions': typeof AddQuestions;
  }
}
