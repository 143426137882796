/* import __COLOCATED_TEMPLATE__ from './rating-reason-input.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { CustomerAnswerRating } from 'embercom/lib/fin-playground';
import { localCopy } from 'tracked-toolbox';

interface RatingReasonInputArgs {
  customerAnswerRatingNote: string;
  selectRating: (rating: CustomerAnswerRating) => void;
  updateRatingNote: (note: string) => void;
  updateIsReasonInputFocused: (isFocused: boolean) => void;
  shouldRenderSuccessUi: boolean;
}

type RatingReasonInputSignature = {
  Args: RatingReasonInputArgs;
  Element: HTMLDivElement;
};

export default class RatingReasonInput extends Component<RatingReasonInputSignature> {
  @localCopy('args.customerAnswerRatingNote') declare inputText: string;

  @action onInputKeydown(event: KeyboardEvent) {
    let target = event.target as HTMLTextAreaElement;

    if (event.key === 'Enter') {
      target.blur();
    }
  }

  @action onFocusOut(event: FocusEvent) {
    event.stopPropagation();
    this.args.updateIsReasonInputFocused(false);

    if ((event.relatedTarget as HTMLElement)?.id === 'playground-positive-feedback') {
      this.args.selectRating(CustomerAnswerRating.Positive);
    } else if ((event.relatedTarget as HTMLElement)?.id === 'playground-negative-feedback') {
      this.args.selectRating(CustomerAnswerRating.Negative);
    } else if (this.inputText !== this.args.customerAnswerRatingNote) {
      this.args.updateRatingNote(this.inputText);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Playground::RatingReasonInput': typeof RatingReasonInput;
  }
}
