/* import __COLOCATED_TEMPLATE__ from './test-settings-modal.hbs'; */
/* RESPONSIBLE TEAM: team-ml */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import type { PlaygroundSettings } from 'embercom/lib/fin-playground';

interface TestSettingsModalArgs {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  playgroundSettings: PlaygroundSettings;
  withAudienceTargeting?: boolean;
}

export default class TestSettingsModal extends Component<TestSettingsModalArgs> {
  @action
  save() {
    this.args.onSave();
    this.args.onClose();
  }

  @action
  onCancel() {
    this.args.playgroundSettings.rollbackChanges();
    this.args.onClose();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Playground::TestSettingsModal': typeof TestSettingsModal;
  }
}
