/* import __COLOCATED_TEMPLATE__ from './alias-icon.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import templateOnlyComponent from '@ember/component/template-only';

const AliasIcon = templateOnlyComponent();
export default AliasIcon;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    AliasIcon: typeof AliasIcon;
  }
}
