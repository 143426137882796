/* import __COLOCATED_TEMPLATE__ from './settings.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import Component from '@glimmer/component';
import type { FinVoiceVadSettings } from 'embercom/objects/ai-agent/fin-voice/fin-voice-events';
import type { AudioDeviceSettings, WebsocketState } from 'embercom/services/fin-voice-service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type { FinVoiceVoice } from 'embercom/objects/ai-agent/fin-voice/fin-voice-voice-options';
import { FIN_VOICE_VOICE_OPTIONS } from 'embercom/objects/ai-agent/fin-voice/fin-voice-voice-options';

export interface SettingsArgs {
  connectionState: WebsocketState;
  vadSettings: FinVoiceVadSettings;
  selectedVoice: FinVoiceVoice;
  audioDeviceSettings: AudioDeviceSettings;
  onSave: (
    vadSettings: FinVoiceVadSettings,
    voice: FinVoiceVoice,
    audioDeviceSettings: AudioDeviceSettings,
  ) => void;
  onClose: () => void;
}

interface Signature {
  Args: SettingsArgs;
  Element: HTMLElement;
}

export default class Settings extends Component<Signature> {
  @tracked threshold = this.args.vadSettings.threshold;
  @tracked prefixPaddingMs = this.args.vadSettings.prefixPaddingMs;
  @tracked silenceDurationMs = this.args.vadSettings.silenceDurationMs;

  @tracked selectedVoice = this.args.selectedVoice;

  @tracked echoCancellation = this.args.audioDeviceSettings.echoCancellation;
  @tracked noiseSuppression = this.args.audioDeviceSettings.noiseSuppression;
  @tracked voiceIsolation = this.args.audioDeviceSettings.voiceIsolation;

  get voiceOptions() {
    return FIN_VOICE_VOICE_OPTIONS;
  }

  get isSessionConnected() {
    return this.args.connectionState !== 'disconnected';
  }

  @action onThresholdChange(event: Event) {
    this.threshold = parseFloat((event.target as HTMLInputElement).value);
  }

  @action onPrefixPaddingMsChange(event: Event) {
    this.prefixPaddingMs = parseFloat((event.target as HTMLInputElement).value);
  }

  @action onSilenceDurationMsChange(event: Event) {
    this.silenceDurationMs = parseFloat((event.target as HTMLInputElement).value);
  }

  @action toggleEchoCancellation() {
    this.echoCancellation = !this.echoCancellation;
  }

  @action toggleNoiseSuppression() {
    this.noiseSuppression = !this.noiseSuppression;
  }

  @action toggleVoiceIsolation() {
    this.voiceIsolation = !this.voiceIsolation;
  }

  @action onSave() {
    this.args.onSave(
      {
        threshold: this.threshold,
        prefixPaddingMs: this.prefixPaddingMs,
        silenceDurationMs: this.silenceDurationMs,
      },
      this.selectedVoice,
      {
        ...this.args.audioDeviceSettings,
        echoCancellation: this.echoCancellation,
        noiseSuppression: this.noiseSuppression,
        voiceIsolation: this.voiceIsolation,
      },
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::VoicePlayground::Settings': typeof Settings;
  }
}
