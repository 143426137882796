/* import __COLOCATED_TEMPLATE__ from './confirm-test-run-modal.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {
    onModalClose: () => void;
    runTest: () => void;
    downloadReport: () => void;
  };
}

const ConfirmTestRunModal = templateOnlyComponent<Signature>();
export default ConfirmTestRunModal;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Playground::ConfirmTestRunModal': typeof ConfirmTestRunModal;
  }
}
