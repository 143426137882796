/* import __COLOCATED_TEMPLATE__ from './playground.hbs'; */
/* RESPONSIBLE TEAM: team-ml */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import ENV from 'embercom/config/environment';
import type IntlService from 'embercom/services/intl';
import {
  deleteRequest,
  postRequest,
  putRequest,
  request,
  ResponseError,
} from 'embercom/lib/inbox/requests';
import { dropTask } from 'ember-concurrency-decorators';
import {
  Playground,
  PlaygroundQuestion,
  PlaygroundSettings,
  Status,
  type CustomerAnswerRating,
  type CustomerRatingReason,
  type QuestionGenerationUpdateEvent,
  QUESTION_GENERATION_UPDATE_EVENT_NAME,
  QuestionGenerationStatus,
  type PlaygroundQuestionWireFormat,
  type PlaygroundQuestionGenerationJobWireFormat,
  PlaygroundQuestionGeneration,
} from 'embercom/lib/fin-playground';
import { AsyncData } from 'embercom/resources/utils/async-data';
import { taskFor } from 'ember-concurrency-ts';
import { cached } from 'tracked-toolbox';
import { use } from 'ember-resources/util/function-resource';
import FinPlaygroundExport from 'embercom/lib/fin-playground-test-export';
import type RouterService from '@ember/routing/router-service';
import type CsvService from 'embercom/services/csv';
import { AI_CHATBOT_CONTENT_CHANGED_ID } from 'embercom/services/real-time-event-service';
import { FinContentStatus } from 'embercom/lib/fin-content-status';
import type AiContentSegmentsService from 'embercom/services/ai-content-segments-service';
import scrollIntoViewIfNotVisible from 'embercom/lib/scroll-into-view-if-not-visible';
import { startSurvey } from 'embercom/lib/intercom-widget-helper';
const NEXUS_TOPICS = ['ai-chatbot-content-changed', 'fin-playground'];

const NexusSubscriptionEvents = [
  { eventName: AI_CHATBOT_CONTENT_CHANGED_ID, handler: '_handleContentChangedEvent' },
  { eventName: 'FinPlaygroundQuestionResponse', handler: 'handleAnswerGenerated' },
  {
    eventName: QUESTION_GENERATION_UPDATE_EVENT_NAME,
    handler: '_handleQuestionGenerationResponse',
  },
];
const SURVEY_ID = 45346448;

interface ContentChangedEvent {
  content_available_changed_at: Date | undefined;
}

export default class AiAgentPlayground extends Component {
  @service declare intl: IntlService;
  @service declare appService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare realTimeEventService: $TSFixMe;
  @service declare csv: CsvService;
  @service declare router: RouterService;
  @service declare intercomEventService: $TSFixMe;
  @service declare aiContentSegmentsService: AiContentSegmentsService;

  @tracked newQuestion = '';
  @tracked selectedQuestionIndex = 0;
  @tracked isAddingQuestions = false;
  @tracked showResetModal = false;
  @tracked showUploadCSVModal = false;
  @tracked isUpdatingAnswerRating = false;
  @tracked showSuggestionsSideSheet = false;
  @tracked showSettingsUpdateModal = false;
  @tracked showConfirmTestRunModal = false;
  @tracked showTestSettingsModal = false;
  @tracked showExceedingQuestionLimitBanner = false;
  @tracked shouldRenderSuccessUi = false;
  @tracked isReasonInputFocused = false;
  @tracked teammateHasNoAccessToAllConversations = false;
  @tracked playgroundLoadingFailed = false;

  unprocessedAnswerGenerationResponses: PlaygroundQuestion[] = [];

  generateAnswersTask = taskFor(this._generateAnswers);
  generateQuestionsTask = taskFor(this._generateQuestionsTask);

  constructor(owner: unknown, args: any) {
    super(owner, args);

    this._subscribeToContentChanges();
    this.aiContentSegmentsService.loadSegments();
  }

  willDestroy() {
    super.willDestroy();

    NexusSubscriptionEvents.forEach(({ eventName, handler }) => {
      this.realTimeEventService.off(eventName, this, handler);
    });

    this.realTimeEventService.unsubscribeTopics(NEXUS_TOPICS);
  }

  _subscribeToContentChanges() {
    this.realTimeEventService.subscribeTopics(NEXUS_TOPICS);

    NexusSubscriptionEvents.forEach(({ eventName, handler }) => {
      this.realTimeEventService.on(eventName, this, handler);
    });
  }

  _handleContentChangedEvent(event: ContentChangedEvent) {
    this.finContentStatusLoader.update(FinContentStatus.deserialize(event));
  }

  @cached
  get playground() {
    return (
      this.playgroundLoader.value ??
      new Playground(
        [],
        new PlaygroundQuestionGeneration(false, undefined, undefined),
        new PlaygroundSettings([]),
      )
    );
  }

  get playgroundIsLoading() {
    return this.playgroundLoader.isLoading;
  }

  get isAiConsentRequired(): boolean {
    if (this.appService.app.canUseFeature('answerbot-fin-playground-ai-consent')) {
      // If answerbot-ai-global-opt-out is enabled, hasConsentedToFinIngestion is equal to the value of hasConsentedToExternalAi in `intercom` app.
      return !this.appService.app.hasConsentedToFinIngestion;
    }

    return false;
  }

  get finContentStatus() {
    return this.finContentStatusLoader.value;
  }

  get selectedQuestion() {
    return this.playground.filteredQuestions[this.selectedQuestionIndex];
  }

  get hasQuestions() {
    return this.playground.questions.length > 0;
  }

  get hasQuestionsRunning() {
    return this.playground.questions.some((q) => q.status === Status.Running);
  }

  get isEmptyStateAndGeneratingQuestions(): boolean {
    return !this.hasQuestions && this.playground.questionGeneration.isRunning;
  }

  get isPlaygroundLocked(): boolean {
    return this.isAiConsentRequired || this.playgroundLoadingFailed;
  }

  get deductionRequiredToAddQuestion(): number {
    if (!this.playground.hasReachedQuestionLimit) {
      return 0;
    }

    return 1 + this.playground.questions.length - this.playground.questionLimit;
  }

  get didAnyQuestionRunBeforeLastContentChange(): boolean {
    if (
      this.playgroundLoader.isLoading ||
      this.finContentStatusLoader.isLoading ||
      this.playground.isRunning
    ) {
      return false;
    }

    let finContentChangedAt = this.finContentStatus?.contentAvailableChangedAt;
    if (!finContentChangedAt) {
      return false;
    }

    return this.playground.questions.some((question) => {
      // Technically the best way would be to compare with start of answer generation rather than `answerLastGeneratedAt`.
      if (!question.answerLastGeneratedAt) {
        return false;
      }

      // finContentChangedAt is already checked for undefined above, so we can safely assert it's presence here.
      return question.answerLastGeneratedAt <= finContentChangedAt!;
    });
  }

  get runTestButtonLabel(): string {
    return this.hasQuestionsRunning
      ? this.intl.t('ai-agent.playground.running-test')
      : this.intl.t('ai-agent.playground.run-test');
  }

  get isModalOpen(): boolean {
    return (
      this.showResetModal ||
      this.showUploadCSVModal ||
      this.isAddingQuestions ||
      this.showSettingsUpdateModal ||
      this.showConfirmTestRunModal ||
      this.showTestSettingsModal
    );
  }

  get canUseArrowHotkeys() {
    return !this.isModalOpen && !this.isReasonInputFocused;
  }

  get addQuestionDropdownOptions() {
    let items = [];

    if (
      this.appService.app.canUseFeature('answerbot-fin-playground-auto-bootstrap') &&
      this.playground.questionGeneration.isPossible
    ) {
      items.push({
        text: this.intl.t('ai-agent.playground.add-questions-dropdown.generate-questions'),
        value: 'generate-questions',
      });
    }

    items.push(
      {
        text: this.intl.t('ai-agent.playground.add-questions-dropdown.upload-csv'),
        value: 'upload-csv',
      },
      {
        text: this.hasQuestions
          ? this.intl.t('ai-agent.playground.add-questions-dropdown.add-questions-manually')
          : this.intl.t('ai-agent.playground.add-question'),
        value: 'add-question',
      },
    );

    return [{ items }];
  }

  get canIncrementQuestionIndex() {
    return this.selectedQuestionIndex < this.playground.filteredQuestions.length - 1;
  }

  get canDecrementQuestionIndex() {
    return this.selectedQuestionIndex > 0;
  }

  get canViewPlaygroundContent() {
    return !this.isAiConsentRequired && !this.teammateHasNoAccessToAllConversations;
  }

  @use private finContentStatusLoader = AsyncData<FinContentStatus>(async () => {
    let response = await request(`/ember/fin_content_status?app_id=${this.appService.app.id}`);
    return response.ok ? FinContentStatus.deserialize(await response.json()) : null;
  });

  get isStandaloneApp() {
    return this.appService.app.canUseStandalone;
  }

  @use private playgroundLoader = AsyncData<Playground>(async () => {
    try {
      let response = await request(`/ember/fin_playground?app_id=${this.appService.app.id}`);

      let playground = Playground.deserialize(await response.json());

      // This is necessary because while the playground is loading, we receive answer generation events from Nexus
      // that aren't processed until the playground is loaded
      // We keep track of these events in `unprocessedAnswerGenerationResponses` and process them once the playground is loaded
      playground.replaceRunningQuestionsWithCompleted(this.unprocessedAnswerGenerationResponses);
      this.unprocessedAnswerGenerationResponses = [];

      this.playgroundLoadingFailed = false;
      return playground;
    } catch (error) {
      if (error.response.status === 401) {
        this.teammateHasNoAccessToAllConversations = true;
      } else {
        this.playgroundLoadingFailed = true;

        this.notificationsService.notifyErrorWithButton(
          this.intl.t('ai-agent.playground.call-error.first-load.message'),
          {
            label: this.intl.t('ai-agent.playground.call-error.first-load.action'),
            action: () => this.playgroundLoader.reload(),
          },
          -1,
        );
      }
      return;
    }
  });

  @action updateShouldRenderSuccessUi(shouldRender: boolean) {
    this.shouldRenderSuccessUi = shouldRender;
  }

  @action updateIsReasonInputFocused(isFocused: boolean) {
    this.isReasonInputFocused = isFocused;
  }

  @action updateShowConfirmTestRunModal(shouldRender: boolean) {
    this.showConfirmTestRunModal = shouldRender;
  }

  @action updateShowTestSettingsModal(shouldRender: boolean) {
    this.showTestSettingsModal = shouldRender;
  }

  @action
  async clearPlayground() {
    try {
      await taskFor(this._clearPlaygroundTask).perform();
    } catch (error) {
      this.notificationsService.notifyError(
        this.intl.t('ai-agent.playground.call-error.clear-playground.message'),
      );
    }
  }

  async saveQuestions(list: string[], source: string) {
    let questions = list
      .uniq()
      .filter((q) => q.trim().length > 0 && !this.playground.hasQuestion(q))
      .map((q) => new PlaygroundQuestion(undefined, q));

    if (questions.length === 0) {
      return;
    }

    if (questions.length > this.playground.remainingQuestionQuota) {
      this.showExceedingQuestionLimitBanner = true;
      questions = questions.slice(0, this.playground.remainingQuestionQuota);
    }

    this.playground.addQuestions(questions);

    try {
      await taskFor(this._addQuestionsTask).perform(questions);

      this.trackAddingNewQuestions(questions.length, source);
    } catch (error) {
      questions.forEach((question) => {
        this.playground.removeQuestion(question.questionText);
      });

      throw error;
    }
  }

  @action
  async addQuestionsFromCsvFile(list: string[]) {
    await this.saveQuestions(list, 'upload_csv_modal');
  }

  @action
  async addQuestionsUsingForm(list: string[]) {
    try {
      await this.saveQuestions(list, 'add_questions_form');
    } catch (error) {
      this.notificationsService.notifyError(
        this.intl.t('ai-agent.playground.call-error.save-questions.message'),
      );

      throw error;
    }
  }

  @action
  async runTest() {
    await this.runQuestions();

    this.instrumentButtonClick('rerun_test');
  }

  @action
  async runQuestions() {
    let originalStatuses = this.playground.questions.map((question) => question.status);

    try {
      this.playground.questions.forEach(function (question) {
        // We change the status for immediate UI feedback that questions are running
        question.status = Status.Running;
      });

      await this.generateAnswersTask.perform(this.playground.pendingQuestions);
    } catch (error) {
      this.playground.questions.forEach((question, index) => {
        question.status = originalStatuses[index];
      });

      this.notificationsService.notifyError(
        this.intl.t('ai-agent.playground.call-error.run-questions.message'),
      );

      throw error;
    }
  }

  @action
  async runSelectedQuestion() {
    if (!this.selectedQuestion?.id) {
      return;
    }

    let originalStatus = this.selectedQuestion.status;

    try {
      this.playground.setQuestionState(this.selectedQuestion.id, Status.Running);

      await this.generateAnswersTask.perform([this.selectedQuestion]);
    } catch (error) {
      this.playground.setQuestionState(this.selectedQuestion.id, originalStatus);

      this.notificationsService.notifyError(
        this.intl.t('ai-agent.playground.call-error.run-question.message'),
      );

      throw error;
    }
  }

  @action
  async removeQuestion(question_id?: number) {
    if (!question_id) {
      return;
    }

    try {
      await taskFor(this._removeQuestionTask).perform(question_id);

      if (this.selectedQuestionIndex === question_id) {
        this.selectedQuestionIndex = 0;
      }
    } catch (error) {
      this.notificationsService.notifyError(
        this.intl.t('ai-agent.playground.call-error.delete-question.message'),
      );

      throw error;
    }
  }

  @action
  generateQuestions() {
    this.generateQuestionsTask.perform();
  }

  @action
  updateQuestionGenerationJob(json: PlaygroundQuestionGenerationJobWireFormat) {
    this.playground.questionGeneration.updateLatestJobData(json);
  }

  @action
  resetQuestionGenerationJob() {
    this.playground.questionGeneration.resetLatestJobData();
  }

  @action
  selectQuestion(questionText: string) {
    this.selectedQuestionIndex =
      this.playground.filteredQuestions.findIndex((q) => q.questionText === questionText) || 0;
    this.updateShouldRenderSuccessUi(false);
  }

  @action incrementSelectedQuestionIndex(e?: Event) {
    e?.preventDefault();
    if (!this.canIncrementQuestionIndex) {
      return;
    }
    let index = this.selectedQuestionIndex;
    this.setQuestionIndex(index + 1);
    this.scrollToSelectedQuestion(index + 1);
    this.updateShouldRenderSuccessUi(false);
  }

  @action decrementSelectedQuestionIndex(e?: Event) {
    e?.preventDefault();
    if (!this.canDecrementQuestionIndex) {
      return;
    }
    let index = this.selectedQuestionIndex;
    this.setQuestionIndex(index - 1);
    this.scrollToSelectedQuestion(index - 1);
    this.updateShouldRenderSuccessUi(false);
  }

  scrollToSelectedQuestion(index: number) {
    let selectedQuestion = document.querySelector(`[data-question-index="${index}"]`);
    if (selectedQuestion) {
      scrollIntoViewIfNotVisible(selectedQuestion, { alignToTop: true });
    }
  }

  @action setQuestionIndex(index: number) {
    this.selectedQuestionIndex = index;
  }

  @action
  async handleAnswerGenerated(eventData: { question: PlaygroundQuestionWireFormat }) {
    let question = PlaygroundQuestion.deserialize(eventData.question);
    if (this.playgroundIsLoading) {
      this.unprocessedAnswerGenerationResponses.push(question);
    } else {
      this.playground.replaceQuestionByText(question);
    }
  }

  @action
  async updateQuestionRating(
    questionId: number,
    args: { rating?: CustomerAnswerRating; reason?: CustomerRatingReason; note?: string },
  ) {
    let { rating, reason, note } = args;

    this.isUpdatingAnswerRating = true;

    await taskFor(this._updateQuestionTask).perform(questionId, rating, reason, note);
    if (typeof note === 'string') {
      this.renderSuccessUiForOneSecond();
    }
  }

  @action renderSuccessUiForOneSecond() {
    this.updateShouldRenderSuccessUi(true);

    setTimeout(() => {
      this.updateShouldRenderSuccessUi(false);
    }, ENV.APP._1000MS);
    return;
  }

  @action
  downloadResults() {
    let { data, fileName } = new FinPlaygroundExport(
      this.appService.app,
      this.intl,
      this.playground,
      this.router,
      this.aiContentSegmentsService.segments,
    );

    this.csv.export(data, {
      fileName,
      withSeparator: false,
    });

    this.intercomEventService.trackAnalyticsEvent({
      action: 'downloaded_results',
      object: 'playground',
    });
  }

  @action
  _handleQuestionGenerationResponse(e: QuestionGenerationUpdateEvent) {
    this.playground.questionGeneration.updateLatestJobData(e);

    if (e.status !== QuestionGenerationStatus.Completed) {
      return;
    }

    if (!e.questions || e.questions.length === 0) {
      return;
    }

    if (e.is_exceeding_question_limit) {
      this.showExceedingQuestionLimitBanner = true;
    }

    let questions = e.questions.map((question) => PlaygroundQuestion.deserialize(question));

    this.playground.addQuestions(questions || []);

    this.trackAddingNewQuestions(e.questions.length, 'ai_bootstrap');
  }

  @dropTask
  *_addQuestionsTask(questions: PlaygroundQuestion[]) {
    if (questions.length === 0) {
      return;
    }

    this.playground.setRunningStatus(questions);

    let response = (yield postRequest(
      `/ember/fin_playground/questions?app_id=${this.appService.app.id}`,
      {
        questions: questions.map((q) => ({ question_text: q.questionText })),
      },
    )) as Response;

    let json = (yield response.json()) as { questions: Array<PlaygroundQuestionWireFormat> };
    for (let question of json.questions) {
      this.playground.replaceQuestionByText(PlaygroundQuestion.deserialize(question));
    }
  }

  @dropTask
  *_removeQuestionTask(question_id: number) {
    yield deleteRequest(
      `/ember/fin_playground/question/${question_id}?app_id=${this.appService.app.id}`,
    );

    this.playground.removeQuestionById(question_id);
  }

  @dropTask
  *_clearPlaygroundTask() {
    let response = (yield postRequest(
      `/ember/fin_playground/clear?app_id=${this.appService.app.id}`,
    )) as Response;
    if (response.ok) {
      this.playgroundLoader.reload();
      this.showResetModal = false;
      this.selectedQuestionIndex = 0;
    }
  }

  @dropTask
  *_updateQuestionTask(
    questionId: number,
    customerAnswerRating?: CustomerAnswerRating,
    customerAnswerRatingReason?: CustomerRatingReason,
    customerAnswerRatingNote?: string,
  ) {
    try {
      let response = (yield putRequest(
        `/ember/fin_playground/question/${questionId}?app_id=${this.appService.app.id}`,
        {
          customer_answer_rating: customerAnswerRating,
          customer_answer_rating_reason: customerAnswerRatingReason,
          customer_answer_rating_note: customerAnswerRatingNote,
        },
      )) as Response;

      if (response.ok) {
        let rawQuestion = (yield response.json()) as PlaygroundQuestionWireFormat;
        let updatedQuestion = PlaygroundQuestion.deserialize(rawQuestion);
        this.playground.replaceQuestionByText(updatedQuestion);
      }
    } catch (err) {
      if (err instanceof ResponseError && err.response.status === 404) {
        this.notificationsService.notifyError(
          this.intl.t('ai-agent.playground.question-deleted-error-message'),
        );
      } else {
        this.notificationsService.notifyError(
          this.intl.t('ai-agent.playground.call-error.update-question.message'),
        );
      }

      throw err;
    } finally {
      this.isUpdatingAnswerRating = false;
    }
  }

  @dropTask
  *_generateAnswers(questions?: PlaygroundQuestion[]) {
    this.playground.setRunningStatus(questions);

    let response = (yield postRequest(
      `/ember/fin_playground/generate_answers?app_id=${this.appService.app.id}`,
      {
        question_ids: questions?.map((q) => q.id),
      },
    )) as Response;

    let json = (yield response.json()) as { questions: Array<PlaygroundQuestionWireFormat> };
    let newQuestions = json.questions.map(PlaygroundQuestion.deserialize);
    for (let newQuestion of newQuestions) {
      this.playground.replaceQuestionByText(newQuestion);
    }
  }

  @dropTask
  *_generateQuestionsTask() {
    try {
      let response = (yield postRequest(
        `/ember/fin_playground/generate_questions?app_id=${this.appService.app.id}`,
      )) as Response;

      let json = (yield response.json()) as PlaygroundQuestionGenerationJobWireFormat;
      this.playground.questionGeneration.updateLatestJobData(json);
    } catch (error) {
      // If there is a job in progress we will receive 409
      if (error instanceof ResponseError && error.response.status === 409) {
        let json = (yield error.response.json()) as PlaygroundQuestionGenerationJobWireFormat;
        this.playground.questionGeneration.updateLatestJobData(json);
      } else {
        this.notificationsService.notifyError(
          this.intl.t('ai-agent.playground.banners.generating-questions.error'),
        );
      }
    }
  }

  trackAddingNewQuestions(new_question_count: number, source: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'added_questions',
      object: 'playground',
      new_question_count,
      source,
    });
  }

  @action instrumentButtonClick(buttonType: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: `${buttonType}_button`,
      context: 'playground',
    });
  }

  @action instrumentIngestionBannerRender() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'rendered',
      object: 'ingestion_banner',
      context: 'playground',
    });
  }

  @action
  toggleSuggestionsSideSheet() {
    this.showSuggestionsSideSheet = !this.showSuggestionsSideSheet;

    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'view_suggestions_button',
      context: 'playground',
      is_open: this.showSuggestionsSideSheet,
      rating_reason: this.selectedQuestion?.customerAnswerRatingReason,
    });
  }

  @action
  selectAddQuestionDropdownOption(option: string) {
    switch (option) {
      case 'generate-questions':
        this.generateQuestions();
        break;
      case 'upload-csv':
        this.showUploadCSVModal = true;
        break;
      case 'add-question':
        this.isAddingQuestions = true;
        break;
    }
  }

  @action handlePlaygroundSettingsChanged() {
    if (!this.playground.settings.hasChanged()) {
      return;
    }

    if (this.playground.hasQuestions) {
      this.showSettingsUpdateModal = true;
    } else {
      taskFor(this._updateSettings).perform();
    }
  }

  @action async updateSettingsAndRunQuestions() {
    await taskFor(this._updateSettings).perform();
    await this.runQuestions();

    this.showSettingsUpdateModal = false;
  }

  @action
  startSurvey() {
    startSurvey(SURVEY_ID);
  }

  @dropTask
  *_updateSettings() {
    try {
      yield postRequest(`/ember/fin_playground/update_settings?app_id=${this.appService.app.id}`, {
        settings: {
          selected_content_segment_ids: this.playground.settings.selectedContentSegmentIds,
        },
      });
      this.playground.settings.refreshTrackedState();
      this.notificationsService.notifyConfirmation(
        this.intl.t('ai-agent.playground.settings.save-success'),
      );
    } catch (error) {
      this.notificationsService.notifyError(this.intl.t('ai-agent.playground.settings.error'));

      throw error;
    }
  }

  @action closeSettingsUpdateModal() {
    this.playground.settings.rollbackChanges();
    this.showSettingsUpdateModal = false;
  }

  get canShowAudienceFilter() {
    return this.appService.app.canUseFinPlaygroundAudienceSelection;
  }

  get shouldShowAudienceFilter() {
    return !this.shouldUseTestSettingsModal && this.canShowAudienceFilter;
  }

  get shouldUseTestSettingsModal() {
    return this.appService.app.canUseFinPlaygroundTestSettingsModal && this.canShowAudienceFilter;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Playground': typeof AiAgentPlayground;
  }
}
