/* import __COLOCATED_TEMPLATE__ from './collapsible-list.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import type Guideline from 'embercom/models/ai-agent/guidelines';

export interface Args {
  title: string;
  category: string;
  description: string;
  guidelines: Guideline[];
  icon: InterfaceIconName;
  cannotAddNewGuidelines: boolean;
  handleUserIsTyping: () => void;
  handleGuidelineDelete: () => void;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

export default class GuidelinesCollapsibleList extends Component<Signature> {
  @service declare appService: any;
  @service declare notificationsService: any;
  @service declare store: any;
  @service declare intl: any;
  @tracked isOpen = true;

  get category(): string {
    return this.args.category;
  }

  get app(): any {
    return this.appService.app;
  }

  get arrowIconClass(): InterfaceIconName {
    return this.isOpen ? 'small-thin-down-arrow' : 'small-thin-right-arrow';
  }

  get maybeEmptyStateClasses(): string {
    return this.args.guidelines.length ? '' : 'filter grayscale opacity-50';
  }

  get headerOpenClassName(): string {
    return 'collapsible-list__header__open net-new-flag';
  }

  get guidelinesForCategory(): Guideline[] {
    return this.args.guidelines.filter(
      (guideline) => guideline.category === this.args.category && !guideline.isDeleted,
    );
  }

  @action
  toggleOpenState(): void {
    this.isOpen = !this.isOpen;
  }

  @action
  createNewGuideline(): void {
    this.store.createRecord('ai-agent/guidelines', {
      prompt: '',
      category: this.category,
    });

    this.isOpen = true;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Guidance::CollapsibleList': typeof GuidelinesCollapsibleList;
  }
}
