/* import __COLOCATED_TEMPLATE__ from './conversation-item.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import templateOnlyComponent from '@ember/component/template-only';
import type {
  FinVoiceConversationEntryFunctionCall,
  FinVoiceConversationEntryMessage,
  FinVoiceConversationEntryStart,
} from 'embercom/objects/ai-agent/fin-voice/fin-voice-session';

export interface ConversationArgs {
  conversationItem: FormattedConversationItem;
  verboseLogsEnabled: boolean;
}

interface Signature {
  Args: ConversationArgs;
  Element: HTMLElement;
}

export type FormattedConversationItem =
  | FormattedConversationMessage
  | FormattedConversationFunctionCall
  | FormattedConversationStart;

export type FormattedConversationMessage = Pick<
  FinVoiceConversationEntryMessage,
  'status' | 'itemId' | 'type' | 'transcript' | 'errorCode'
> & {
  participant: 'user' | 'fin';
  timestamp: string;
};

export type FormattedConversationFunctionCall = Pick<
  FinVoiceConversationEntryFunctionCall,
  'status' | 'itemId' | 'type' | 'toolName' | 'toolCallId' | 'toolOutput'
> & {
  toolArguments: string;
  timestamp: string;
};

export type FormattedConversationStart = Pick<
  FinVoiceConversationEntryStart,
  'status' | 'itemId' | 'type' | 'streamId' | 'voice'
> & {
  timestamp: string;
  vadSettings: string;
};

const ConversationItem = templateOnlyComponent<Signature>();
export default ConversationItem;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::VoicePlayground::ConversationItem': typeof ConversationItem;
  }
}
