/* import __COLOCATED_TEMPLATE__ from './connection-controls.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type IntlService from 'ember-intl/services/intl';
import type { WebsocketState } from 'embercom/services/fin-voice-service';

export interface ConnectionControlsArgs {
  connectionState: WebsocketState;
  isMuted: boolean;
  connect: () => Promise<void>;
  disconnect: () => Promise<void>;
  mute: () => Promise<void>;
  unmute: () => Promise<void>;
}

interface Signature {
  Args: ConnectionControlsArgs;
  Element: HTMLElement;
}

export default class ConnectionControls extends Component<Signature> {
  @service declare intl: IntlService;

  get isConnected() {
    return this.args.connectionState === 'connected';
  }

  get isConnecting() {
    return this.args.connectionState === 'connecting';
  }

  get isDisconnecting() {
    return this.args.connectionState === 'disconnecting';
  }

  get buttonText() {
    switch (this.args.connectionState) {
      case 'connected':
        return this.intl.t('ai-agent.voice-playground.disconnect');
      case 'connecting':
        return this.intl.t('ai-agent.voice-playground.connecting');
      case 'disconnecting':
        return this.intl.t('ai-agent.voice-playground.disconnecting');
      default:
        return this.intl.t('ai-agent.voice-playground.connect');
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::VoicePlayground::ConnectionControls': typeof ConnectionControls;
  }
}
