/* import __COLOCATED_TEMPLATE__ from './conversation.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { trackedRef } from 'ember-ref-bucket';
import { DeduplicatedAsyncData } from 'embercom/resources/utils/async-data';
import { scrollToBottom, isScrolledToBottom } from 'embercom/helpers/scrolling-helpers';
import { action } from '@ember/object';
import type { FinVoiceSession } from 'embercom/objects/ai-agent/fin-voice/fin-voice-session';
import type { FormattedConversationItem } from './conversation-item';

export interface ConversationArgs {
  sessions: FinVoiceSession[];
  verboseLogsEnabled: boolean;
}

interface Signature {
  Args: ConversationArgs;
  Element: HTMLElement;
}

type FormattedSession = Pick<FinVoiceSession, 'sessionError' | 'sessionId'> & {
  sessionStartTimestamp: string;
  sessionEndTimestamp: string | null;
  conversation: FormattedConversationItem[];
};

export default class Conversation extends Component<Signature> {
  @tracked private _userHasScrolled = false;
  @tracked hasNewMessage = false;

  @trackedRef('containerElement')
  private _containerElement?: HTMLElement;

  sessionsWatcher = DeduplicatedAsyncData(
    this,
    () => [this.args.sessions],
    (sessions) => {
      if (!this._userHasScrolled) {
        if (this._containerElement) {
          requestAnimationFrame(() => {
            scrollToBottom(this._containerElement!);
          });
        }

        this.hasNewMessage = false;
      } else {
        this.hasNewMessage = true;
      }
      return sessions;
    },
  );

  get formattedSessions(): FormattedSession[] {
    return (this.sessionsWatcher.value || []).map(
      ({ sessionStartTimestamp, sessionEndTimestamp, sessionError, sessionId, conversation }) => {
        let formattedConversation: FormattedConversationItem[] = [];

        conversation.messages.forEach((message) => {
          switch (message.type) {
            case 'function_call':
              formattedConversation.push({
                timestamp: new Date(message.timestamp).toLocaleTimeString(),
                toolName: message.toolName,
                toolCallId: message.toolCallId,
                toolArguments: JSON.stringify(message.toolArguments),
                toolOutput: message.toolOutput,
                status: message.status,
                itemId: message.itemId,
                type: message.type,
              });
              break;
            case 'start':
              formattedConversation.push({
                timestamp: new Date(message.timestamp).toLocaleTimeString(),
                status: message.status,
                itemId: message.itemId,
                type: message.type,
                vadSettings: JSON.stringify(message.vadSettings),
                voice: message.voice,
                streamId: message.streamId,
              });
              break;
            default:
              formattedConversation.push({
                participant: message.role === 'user' ? 'user' : 'fin',
                timestamp: new Date(message.timestamp).toLocaleTimeString(),
                transcript: message.transcript,
                status: message.status,
                itemId: message.itemId,
                errorCode: message.errorCode,
                type: message.type,
              });
              break;
          }
        });

        return {
          sessionId,
          sessionStartTimestamp: new Date(sessionStartTimestamp).toLocaleTimeString(),
          sessionEndTimestamp: sessionEndTimestamp
            ? new Date(sessionEndTimestamp).toLocaleTimeString()
            : null,
          sessionError,
          conversation: formattedConversation,
        };
      },
    );
  }

  @action
  handleScroll() {
    let isAtBottom = isScrolledToBottom(this._containerElement);
    this._userHasScrolled = !isAtBottom;
  }

  @action
  jumpToBottom() {
    if (this._containerElement) {
      scrollToBottom(this._containerElement);
      this.hasNewMessage = false;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::VoicePlayground::Conversation': typeof Conversation;
  }
}
