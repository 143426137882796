/* import __COLOCATED_TEMPLATE__ from './update-settings-modal.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {
    onModalClose: () => void;
    updateSettingsAndRunQuestions: () => void;
    downloadReport: () => void;
  };
}

const UpdateSettingsModal = templateOnlyComponent<Signature>();
export default UpdateSettingsModal;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Playground::UpdateSettingsModal': typeof UpdateSettingsModal;
  }
}
