/* import __COLOCATED_TEMPLATE__ from './suggestion-box.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';

type SuggestionBoxSignature = {
  Args: {
    icon: 'fin' | 'hand' | 'rules' | 'language' | 'new' | 'article' | 'long-text';
    title: string;
    description: string | string[];
    buttonLabel: string;
    onClick: () => void;
  };
};

export default class SuggestionBox extends Component<SuggestionBoxSignature> {
  get descriptionLines() {
    return Array.isArray(this.args.description) ? this.args.description : [this.args.description];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Playground::SuggestionBox': typeof SuggestionBox;
  }
}
