/* import __COLOCATED_TEMPLATE__ from './fin-answer-rating.hbs'; */
/* RESPONSIBLE TEAM: team-ml */

import { action } from '@ember/object';
import Component from '@glimmer/component';
import {
  CustomerAnswerRating,
  CustomerRatingReason,
  Status,
  type PlaygroundQuestion,
} from 'embercom/lib/fin-playground';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import { tracked } from '@glimmer/tracking';

interface FinAnswerRatingtArgs {
  questionIndex: number;
  countOfQuestions: number;
  incrementQuestionIndex: (e?: Event) => void;
  decrementQuestionIndex: (e?: Event) => void;
  question?: PlaygroundQuestion;
  onUpdateQuestionRating: (
    questionId: number,
    args: { rating?: CustomerAnswerRating; reason?: CustomerRatingReason; note?: string },
  ) => void;
  isUpdatingAnswerRating: boolean;
  isModalOpen: boolean;
  toggleSuggestionsSideSheet: () => void;
  shouldRenderSuccessUi: boolean;
  updateIsReasonInputFocused: (isFocused: boolean) => void;
  isReasonInputFocused: boolean;
}

type FinAnswerRatingSignature = {
  Args: FinAnswerRatingtArgs;
  Element: HTMLDivElement;
};

export default class FinAnswerRating extends Component<FinAnswerRatingSignature> {
  CustomerAnswerRating = CustomerAnswerRating;
  CustomerRatingReason = CustomerRatingReason;

  @service declare intl: IntlService;
  @tracked shouldRenderSuccessUi = false;

  replaceUnderscoreWithDash(str: string) {
    return str.replace(/_/g, '-');
  }

  get answer() {
    return this.args.question?.responseText;
  }

  get shouldMarkAsPositive() {
    if (this.args.question?.customerAnswerRating === CustomerAnswerRating.Negative) {
      return false;
    }

    return this.args.question?.customerAnswerRating === CustomerAnswerRating.Positive;
  }

  get shouldDisableFeedbackButtons() {
    return this.args.question?.status === Status.Running || this.args.question?.answerHasError;
  }

  get shouldDisableRatingsHotkeys() {
    return (
      this.shouldDisableFeedbackButtons || this.args.isModalOpen || this.args.isReasonInputFocused
    );
  }

  get selectedCustomerFeedbackReason() {
    if (this.args.question?.customerAnswerRatingReason === null) {
      return undefined;
    }

    return this.args.question?.customerAnswerRatingReason;
  }

  @action updateRatingNote(note: string) {
    if (!this.args.question?.id) {
      return;
    }

    this.args.onUpdateQuestionRating(this.args.question.id, {
      note,
    });
  }

  @action
  selectRatingReason(reason: CustomerRatingReason) {
    if (!this.args.question?.id) {
      return;
    }

    this.args.onUpdateQuestionRating(this.args.question.id, {
      reason,
    });
  }

  @action selectRating(answerRating: CustomerAnswerRating) {
    if (!this.args.question?.id) {
      return;
    }

    if (answerRating === this.args.question.customerAnswerRating) {
      // If answer rating is already selected, deselect it
      this.args.onUpdateQuestionRating(this.args.question.id, { rating: undefined });
    } else {
      // Otherwise update with new rating
      this.args.onUpdateQuestionRating(this.args.question.id, { rating: answerRating });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Playground::FinAnswerRating': typeof FinAnswerRating;
  }
}
