/* import __COLOCATED_TEMPLATE__ from './about-component.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-observers */
/* eslint-disable ember/no-on-calls-in-components */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { once } from '@ember/runloop';
import { computed, observer, action } from '@ember/object';
import { on } from '@ember/object/evented';
import { reads, empty, oneWay, not, or, and } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import {
  applyFunction,
  lessThanProperty,
  notEqualToProperty,
} from '@intercom/pulse/lib/computed-properties';
import { isValidUrlWithProtocol } from 'embercom/lib/url-validator';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

const SHORTCUT_GROUP = 'admin-about';
// This is used in place of the email validation in lib as it's more restrictive.
// We want to allow things like first.last.123, but not other things that are
// valid like "first.last!!?<>".
// This matches only alphanumeric emails with one or more characters and no
// periods at the start or end.
const VALID_EMAIL_REGEX = /^(?:[a-z\d]+(?:\.[a-z\d]*?)?)+$/;

export default Component.extend({
  intl: service(),
  intercomEventService: service(),
  notificationsService: service(),
  shortcuts: service(),
  appService: service(),
  maxEmailLength: 64,
  maxUrlLength: 500,
  maxBiographyLength: 160,
  editableName: reads('model.name'),

  canUseAlias: reads('appService.app.canUseAliasNames'),

  editableEmail: computed('model.profile.email_address_for_app', function () {
    return this.get('model.profile.email_address_for_app').split('@')[0];
  }),

  emailHostPart: computed('model.profile.email_address_for_app', function () {
    return this.get('model.profile.email_address_for_app').split('@')[1];
  }),

  editableEmailIncludingHostPart: computed('editableEmail', 'emailHostPart', function () {
    return `${this.editableEmail}@${this.emailHostPart}`;
  }),

  editableJobTitle: reads('model.profile.job_title'),
  isEditableJobTitleEmpty: empty('editableJobTitle'),
  editableDepartment: reads('model.profile.department'),
  isEditableDepartmentEmpty: empty('editableDepartment'),
  editablePhoneNumber: reads('model.profile.phone_number'),
  isEditablePhoneNumberEmpty: empty('editablePhoneNumber'),
  editableCalendarUrl: reads('model.profile.calendar_url'),
  editableBiography: reads('model.profile.biography'),
  hideCalenderUrlInMessenger: not('model.profile.show_calendar_in_messenger'),
  editableNameInputClasses: reads('nameInputClasses'),
  editableEmailInputClasses: reads('emailInputClasses'),
  editableBioTextClasses: reads('bioTextClasses'),
  editablePhoneNumberClasses: reads('phoneNumberClasses'),
  editableAliasName: reads('model.alias_name'),
  nameHasChanged: notEqualToProperty('model.name', 'editableName'),
  aliasHasChanged: notEqualToProperty('model.alias_name', 'editableAliasName'),
  aliasSenderEmailAddressSettingsId: reads('model.profile.alias_sender_email_address_settings_id'),
  emailAddress: reads('model.email'),
  emailHasChanged: notEqualToProperty(
    'model.profile.email_address_for_app',
    'editableEmailIncludingHostPart',
  ),

  jobTitleHasChanged: notEqualToProperty('model.profile.job_title', 'editableJobTitle'),
  departmentHasChanged: notEqualToProperty('model.profile.department', 'editableDepartment'),
  phoneNumberHasChanged: notEqualToProperty('model.profile.phone_number', 'editablePhoneNumber'),
  biographyHasChanged: notEqualToProperty('model.profile.biography', 'editableBiography'),
  calendarUrlHasChanged: notEqualToProperty('model.profile.calendar_url', 'editableCalendarUrl'),
  togglableCalendar: oneWay('model.profile.show_calendar_in_messenger'),

  showCalendarHasChanged: notEqualToProperty(
    'model.profile.show_calendar_in_messenger',
    'togglableCalendar',
  ),

  togglableLocation: oneWay('model.profile.show_location'),
  hideLocation: not('model.profile.show_location'),
  showLocationHasChanged: notEqualToProperty('model.profile.show_location', 'togglableLocation'),

  toggleableAlias: oneWay('model.profile.alias_enabled'),
  aliasEnabled: reads('model.profile.alias_enabled'),
  showAliasHasChanged: notEqualToProperty('model.profile.alias_enabled', 'toggleableAlias'),

  profileUpdated: or(
    'nameHasChanged',
    'aliasHasChanged',
    'emailHasChanged',
    'jobTitleHasChanged',
    'departmentHasChanged',
    'phoneNumberHasChanged',
    'biographyHasChanged',
    'showLocationHasChanged',
    'calendarUrlHasChanged',
    'showCalendarHasChanged',
    'showAliasHasChanged',
  ),

  nameError: empty('editableName'),
  aliasNameError: empty('editableAliasName'),

  emailError: computed('editableEmail', 'emailHasChanged', 'maxEmailLength', function () {
    if (!this.emailHasChanged) {
      return false;
    }

    let email = this.editableEmail.toLowerCase();
    return !VALID_EMAIL_REGEX.test(email) && email.length <= this.maxEmailLength;
  }),

  phoneNumberError: computed('editablePhoneNumber', 'phoneNumberHasChanged', function () {
    if (!this.phoneNumberHasChanged || this.isEditablePhoneNumberEmpty) {
      return false;
    }
    let parsed = parsePhoneNumberFromString(this.editablePhoneNumber);
    return parsed ? !parsed.isValid() : true;
  }),

  isCalendarUrlEmpty: empty('editableCalendarUrl'),
  calendarUrlLengthIsValid: lessThanProperty('editableCalendarUrl.length', 'maxUrlLength'),
  isCalendarValidUrl: applyFunction(isValidUrlWithProtocol, 'editableCalendarUrl'),

  calendarUrlError: computed('editableCalendarUrl', 'calendarUrlHasChanged', function () {
    if (!this.calendarUrlHasChanged || this.isCalendarUrlEmpty) {
      return false;
    }
    return !(this.isCalendarValidUrl && this.calendarUrlLengthIsValid);
  }),

  aliasError: computed('aliasEnabled', 'aliasNameError', function () {
    return !!(this.aliasEnabled && this.aliasNameError);
  }),

  biographyError: false,
  errorsPresent: or(
    'nameError',
    'biographyError',
    'emailError',
    'calendarUrlError',
    'phoneNumberError',
    'aliasError',
  ),

  initialize: on('didInsertElement', function () {
    this.shortcuts.register(SHORTCUT_GROUP, this, [
      { key: 'escape', textBox: true, method: this.escapeShortcut },
    ]);
  }),

  detroy: on('willDestroyElement', function () {
    this.shortcuts.unregister(SHORTCUT_GROUP);
  }),

  editableMode: false,
  showErrorOnLink: false,
  isNotEditableMode: not('editableMode'),
  shouldHideLocationData: and('isNotEditableMode', 'hideLocation'),

  socialConnectParams: computed('app', function () {
    return `app_id=${this.get('app.id')}`;
  }),

  handleJobTitleChange: on(
    'init',
    observer({
      dependentKeys: ['model.profile.job_title'],

      fn() {
        once(this, this.setEditableJobTitle);
      },

      sync: true,
    }),
  ),

  setEditableJobTitle() {
    if (this.isEditableJobTitleEmpty) {
      this.set('editableJobTitle', this.get('model.profile.job_title'));
    }
  },

  handleDepartmentChange: on(
    'init',
    observer({
      dependentKeys: ['model.profile.department'],

      fn() {
        once(this, this.setEditableDepartment);
      },

      sync: true,
    }),
  ),

  setEditableDepartment() {
    if (this.isEditableDepartmentEmpty) {
      this.set('editableDepartment', this.get('model.profile.department'));
    }
  },

  handlePhoneNumberChange: on(
    'init',
    observer({
      dependentKeys: ['model.profile.phone_number'],

      fn() {
        once(this, this.setEditablePhoneNumber);
      },

      sync: true,
    }),
  ),

  setEditablePhoneNumber() {
    if (this.isEditablePhoneNumberEmpty) {
      this.set('editablePhoneNumber', this.get('model.profile.phone_number'));
    }
  },

  checkAndShowErrors() {
    this.resetErrors();
    this.checkAndSetNameError();
    this.checkAndSetEmailError();
    this.checkAndSetBioError();
    this.checkAndSetPhoneNumberError();
    this.validateCalendarUrl();
    this.checkAndSetAliasNameError();
  },

  resetErrors() {
    this.notificationsService.removeNotification('profileNameError');
    this.notificationsService.removeNotification('profileEmailError');
    this.notificationsService.removeNotification('profileBioError');
    this.notificationsService.removeNotification('profileCalendarUrlError');
    this.notificationsService.removeNotification('profilePhoneNumberError');
    this.notificationsService.removeNotification('profileAliasNameError');

    this.set('editableNameInputClasses', this.nameInputClasses);
    this.set('editableEmailInputClasses', this.emailInputClasses);
    this.set('editableBioTextClasses', this.bioTextClasses);
    this.set('editablePhoneNumberClasses', this.phoneNumberClasses);
  },

  checkAndSetNameError() {
    if (this.nameError) {
      this.set('editableNameInputClasses', `${this.nameInputClasses} o__error`);
      this.notificationsService.notifyWarning(
        this.intl.t('components.admin.about-component.enter-name-warning'),
        3000,
        'profileNameError',
      );
    }
  },

  checkAndSetEmailError() {
    if (this.emailError) {
      this.set('editableEmailInputClasses', `${this.emailInputClasses} o__error`);
      this.notificationsService.notifyWarning(
        this.intl.t('components.admin.about-component.email-warning', {
          maxEmailLength: this.maxEmailLength,
        }),
        6000,
        'profileEmailError',
      );
    }
  },

  checkAndSetBioError() {
    if (this.biographyError) {
      this.set('editableBioTextClasses', `${this.bioTextClasses} o__error`);
      this.notificationsService.notifyWarning(
        this.intl.t('components.admin.about-component.profile-bio-warning', {
          maxBiographyLength: this.maxBiographyLength,
        }),
        3000,
        'profileBioError',
      );
    }
  },

  checkAndSetPhoneNumberError() {
    if (this.phoneNumberError) {
      this.set('editablePhoneNumberClasses', `${this.phoneNumberClasses} o__error`);
      this.notificationsService.notifyWarning(
        this.intl.t('components.admin.about-component.phone-number-warning'),
        6000,
        'profilePhoneNumberError',
      );
    }
  },

  checkAndSetAliasNameError() {
    if (this.aliasError) {
      this.notificationsService.notifyWarning(
        this.intl.t('components.admin.about-component.enter-alias-warning'),
        3000,
        'profileAliasNameError',
      );
    }
  },

  validateCalendarUrl() {
    if (this.calendarUrlError) {
      this.notificationsService.notifyWarning(
        this.intl.t('components.admin.about-component.invalid-calendar-url-warning'),
        3000,
        'profileCalendarUrlError',
      );
      this.set('showErrorOnLink', true);
    } else {
      this.set('showErrorOnLink', false);
    }
  },

  escapeShortcut() {
    if (this.editableMode) {
      this.resetProperties();
      this.exitEditMode();
    }
  },

  resetProperties() {
    this.setProperties({
      editableName: this.get('model.name'),
      editableJobTitle: this.get('model.profile.job_title'),
      editableDepartment: this.get('model.profile.department'),
      editablePhoneNumber: this.get('model.profile.phone_number'),
      editableBiography: this.get('model.profile.biography'),
      editableEmail: this.emailLocalPart,
      editableAliasName: this.get('model.alias_name'),
    });
  },

  exitEditMode() {
    this.set('editableMode', false);
    this.set('biographyError', false);
  },

  trackEventsForChangedAttributes() {
    if (this.jobTitleHasChanged) {
      this.intercomEventService.trackEvent('teammate-profile-updated-job-title');
    }
    if (this.phoneNumberHasChanged) {
      this.intercomEventService.trackEvent('teammate-profile-updated-phone-number');
    }
    if (this.biographyHasChanged) {
      this.intercomEventService.trackEvent('teammate-profile-updated-biography');
    }
    if (this.showLocationHasChanged) {
      this.intercomEventService.trackAnalyticsEvent({
        action: this.hideLocation ? 'checked' : 'unchecked',
        object: 'hide_location_from_users',
      });
    }
    if (this.showAliasHasChanged) {
      this.intercomEventService.trackAnalyticsEvent({
        action: this.aliasEnabled ? 'checked' : 'unchecked',
        object: 'hide_identity_from_users',
      });
    }
  },

  enterEditMode: action(function () {
    this.set('editableMode', true);
  }),

  save: action(function () {
    this.checkAndShowErrors();

    if (this.errorsPresent) {
      return;
    }

    if (!this.profileUpdated) {
      this.exitEditMode();
      return;
    } else {
      this.trackEventsForChangedAttributes();
    }

    let payload = {
      name: this.editableName,
      job_title: this.editableJobTitle,
      profile_department: this.editableDepartment,
      phone_number: this.editablePhoneNumber,
      biography: this.editableBiography,
      calendar_url: this.editableCalendarUrl,
      show_calendar_in_messenger: this.togglableCalendar,
      show_location: this.togglableLocation,
      email_address_for_app: this.editableEmailIncludingHostPart,
      alias_enabled: this.toggleableAlias,
    };

    if (this.toggleableAlias) {
      payload.alias_name = this.editableAliasName;
    }

    this.saveAction(payload);
    this.exitEditMode();
  }),

  toggleShowLocation: action(function () {
    this.set('hideLocation', this.togglableLocation);
    this.toggleProperty('togglableLocation');
  }),

  toggleShowAlias: action(function (event) {
    if (event && event.target) {
      this.set('aliasEnabled', event.target.checked);
    } else {
      this.toggleProperty('aliasEnabled');
    }
    this.toggleProperty('toggleableAlias');
  }),
});
