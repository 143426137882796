/* import __COLOCATED_TEMPLATE__ from './guideline-card.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type Guideline from 'embercom/models/ai-agent/guidelines';
import { dropTask } from 'ember-concurrency-decorators';
import type { TaskGenerator } from 'ember-concurrency';
import { put, ajaxDelete } from 'embercom/lib/ajax';
import { states } from 'embercom/models/data/matching-system/matching-constants';
import { action } from '@ember/object';
import type IntercomConfirmService from 'embercom/services/intercom-confirm-service';

export interface Args {
  guideline: Guideline;
  handleUserIsTyping: () => void;
  onDelete: () => void;
  index: number;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

export default class GuidelineCard extends Component<Signature> {
  @service declare appService: any;
  @service declare notificationsService: any;
  @service declare store: any;
  @service declare intl: any;
  @service declare intercomConfirmService: IntercomConfirmService;

  @tracked isOpen = true;
  guideline = this.args.guideline;

  cancelHooks: (() => void)[] = [];

  MAX_CHARACTER_COUNT = 2500; // remove when cleaning up guidance-template-variables FF: this value has been moved to the composer component directly

  get app(): any {
    return this.appService.app;
  }

  get hasUnsavedChanges(): boolean {
    return Boolean(this.guideline.hasDirtyAttributes);
  }

  get validationErrors(): string[] {
    return this.guideline.validations.isInvalid ? this.guideline.validations.messages : [];
  }

  get isInvalid(): boolean {
    return this.guideline.validations.isInvalid;
  }

  get isValid(): boolean {
    return !this.isInvalid;
  }

  @action
  registerCancelHook(hook: () => void) {
    this.cancelHooks.push(hook);
  }

  @action
  unregisterCancelHook(hook: () => void) {
    this.cancelHooks.removeObject(hook);
  }

  @action
  cancel(): void {
    this.guideline.rollbackAttributes();
    this.cancelHooks.forEach((hook) => hook());
  }

  @dropTask
  *save(): TaskGenerator<void> {
    try {
      yield this._saveChangedGuideline();
      this.notificationsService.notifyConfirmation(
        this.intl.t('ai-agent.guidance.save-success-confirmation'),
      );
    } catch (e) {
      this.notificationsService.notifyResponseError(e);
    }
  }

  @dropTask
  *saveAndEnableGuideline(): TaskGenerator<void> {
    let record = this.guideline;
    try {
      yield this._saveChangedGuideline();
      let response = yield put(`/ember/ai_agent/guidelines/${record.id}/enable`, {
        id: record.id,
        app_id: this.appService.app.id,
      });
      if (response) {
        // Only update state if request was successful
        record.state = states.live;
      }
      this.notificationsService.notifyConfirmation(
        this.intl.t('ai-agent.guidance.save-success-confirmation'),
      );
    } catch (e) {
      this.notificationsService.notifyResponseError(e);
    }
  }

  @dropTask
  *deleteGuideline(): TaskGenerator<void> {
    let record = this.guideline;
    let confirmed = yield this.intercomConfirmService.confirm({
      title: this.intl.t('ai-agent.guidance.guidelines.delete-confirmation.title'),
      body: this.intl.t('ai-agent.guidance.guidelines.delete-confirmation.body'),
      confirmButtonText: this.intl.t(
        'ai-agent.guidance.guidelines.delete-confirmation.confirm-button-text',
      ),
    });
    if (!confirmed) {
      return;
    }
    try {
      yield ajaxDelete(`/ember/ai_agent/guidelines/${record.id}`, {
        id: record.id,
        app_id: this.appService.app.id,
      });
      yield this.store.unloadRecord(record);
      yield this.args.onDelete();
      this.notificationsService.notifyConfirmation(
        this.intl.t('ai-agent.guidance.delete-success-confirmation'),
      );
    } catch (e) {
      this.notificationsService.notifyResponseError(e);
    }
  }

  async _saveChangedGuideline(): Promise<void> {
    let record = this.guideline;
    if (!record.hasDirtyAttributes) {
      return;
    }

    await record.save();
  }

  @dropTask
  *disableGuideline(): TaskGenerator<void> {
    let record = this.guideline;
    try {
      let response = yield put(`/ember/ai_agent/guidelines/${record.id}/disable`, {
        id: record.id,
        app_id: this.appService.app.id,
      });
      if (response) {
        // Only update state if request was successful
        record.state = states.paused;
      }
      this.notificationsService.notifyConfirmation(
        this.intl.t('ai-agent.guidance.save-success-confirmation'),
      );
    } catch (e) {
      this.notificationsService.notifyResponseError(e);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Guidance::GuidelineCard': typeof GuidelineCard;
  }
}
