/* import __COLOCATED_TEMPLATE__ from './admin-email-alias-side-drawer.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { put } from 'embercom/lib/ajax';
import { getAdminProfile } from 'embercom/lib/admins/admin-profiles';
import type InboxApi from 'embercom/services/inbox-api';
import type IntlService from 'ember-intl/services/intl';
import type { Domain } from 'embercom/services/domain-service';
import type Store from '@ember-data/store';
import type { DomainEmailAddress } from 'embercom/services/domain-service';
import { getDomainName } from 'embercom/lib/email';
import { taskFor } from 'ember-concurrency-ts';
import { restartableTask } from 'ember-concurrency-decorators';

interface Args {
  isOpen: boolean;
  onClose: () => void;
  aliasSenderEmailAddressSettingsId: string;
  aliasName: string;
}

interface SenderAddressItem {
  value: string | number;
  text: string;
  component: string;
  componentAttrs?: {
    verified?: boolean;
    isSelected?: boolean;
    isAuthenticated?: any;
  };
  isDisabled?: boolean;
}

export default class AdminEmailAliasSideDrawer extends Component<Args> {
  @service declare appService: $TSFixMe;
  @service declare inboxApi: InboxApi;
  @service intercomEventService: $TSFixMe;
  @service intl!: IntlService;
  @service declare notificationsService: $TSFixMe;
  @service declare store: Store;

  @tracked showAddCustomSenderModal = false;

  @service declare domainService: $TSFixMe;
  @tracked selectedSenderEmailAddressSettingsId?: string | number;

  declare model: any;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    taskFor(this.loadDomainsTask).perform();
  }

  get addressItems(): SenderAddressItem[] {
    let senderEmailAddressSettings = this.domainService.domains.flatMap(
      (domain: Domain) => domain.addresses,
    );
    let items = senderEmailAddressSettings?.length
      ? senderEmailAddressSettings.map((address: DomainEmailAddress) => ({
          value: address.senderEmailAddressSettings?.id ?? 0,
          text: address.senderEmailAddressSettings?.email ?? '',
          isDisabled: this.isItemDisabled(address),
          component: 'new-settings/workspace/brands/brand-email-dropdown-item',
          componentAttrs: {
            verified: address.verified,
            id: address.senderEmailAddressSettings?.id,
            isSelected:
              this.selectedSenderEmailAddressSettingsId === address.senderEmailAddressSettings?.id,
            isAuthenticated: address.senderEmailAddressSettings
              ? this.isEmailDomainAuthenticated(address.senderEmailAddressSettings.email)
              : false,
          },
        }))
      : [
          {
            value: 0,
            text: '',
            component: 'new-settings/channels/email/advanced/email-dropdown-empty-list',
            isDisabled: true,
          },
        ];
    return items;
  }

  get selectedItem() {
    return this.selectedSenderEmailAddressSettingsId;
  }

  get app() {
    return this.appService.app;
  }

  get hasPermissionForCustomAddresses() {
    return this.app.currentAdmin.currentAppPermissions.can_send_from_custom_addresses;
  }

  isItemDisabled(address: DomainEmailAddress) {
    return !this.isEmailDomainAuthenticated(address.email) || !address.verified;
  }

  isEmailDomainAuthenticated(email: string) {
    let emailDomain = getDomainName(email);
    return this.domainService.domains.find((domain: Domain) => domain.name === emailDomain)
      ?.isAuthenticated;
  }

  @action
  updateSelectedEmail(id: string | undefined) {
    this.selectedSenderEmailAddressSettingsId = id;
  }

  @action
  async saveAliasEmail() {
    try {
      let data = {
        app_id: this.app.id,
        id: this.app.currentAdmin.id,
        sender_email_address_settings_id: this.selectedSenderEmailAddressSettingsId,
        alias_enabled: true,
        alias_name: this.args.aliasName,
      };

      await put(`/ember/admins/${this.app.currentAdmin.id}`, data);

      let adminProfile = await getAdminProfile(this.store, this.app.id, this.app.currentAdmin.id);
      this.app.currentAdmin.set('profile', adminProfile);
      this.args.onClose();
      this.notificationsService.notifyConfirmation(
        this.intl.t('components.admin.about-component.alias.email.changes-saved'),
      );
    } catch (error) {
      this.notificationsService.notifyError(
        this.intl.t('components.admin.about-component.alias.email.not-updated'),
      );
    }
  }

  @action
  clearAliasEmail() {
    this.selectedSenderEmailAddressSettingsId = undefined;
  }

  @action
  toggleDisplayCustomSenderModal() {
    this.showAddCustomSenderModal = !this.showAddCustomSenderModal;
    if (this.showAddCustomSenderModal) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'create_custom_sender_address',
        context: 'from_admin_about_profile',
      });
    }
  }

  @restartableTask
  *loadDomainsTask() {
    if (!this.domainService.domains || this.domainService.domains.length === 0) {
      yield this.domainService.load();
    }
    let storedSelection = this.args.aliasSenderEmailAddressSettingsId?.toString();
    let matchingItem = this.addressItems.find((item) => item.value.toString() === storedSelection);
    if (matchingItem) {
      this.selectedSenderEmailAddressSettingsId = matchingItem.value;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Admin::AdminEmailAliasSideDrawer': typeof AdminEmailAliasSideDrawer;
    'admin/admin-email-alias-side-drawer': typeof AdminEmailAliasSideDrawer;
  }
}
